import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// components

// styles

export default function Asset(props) {
  const { data, title } = props;
  const image = getImage(data);

  return (
    <GatsbyImage image={ image } alt={ title } />
  )
}
